import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import HeroMini from "../../components/HeroMini/HeroMini";
import styled from "styled-components";

const Title = styled.h1`
  ${({ theme }) => theme.common.typography.heroLarge};
  text-transform: uppercase;
  color: ${({ theme }) => theme.common.palette.brownGray};
  line-height: 40px;
`;

const BlogContainer = ({ data }) => {
  const { blogheroimage, blogherotext } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <HeroMini
        backgroundImage={blogheroimage}
        render={<Title style={{ color: "white" }}>{blogherotext}</Title>}
        style={{
          marginTop: "0px",
        }}
        fullWidth
      />
      <section className="section">
        <div className="container">
          <div className="content">
            <BlogRoll />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogContainerQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "blogindex" } }) {
          frontmatter {
            blogheroimage {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            blogherotext
          }
        }
      }
    `}
    render={(data) => <BlogContainer data={data} />}
  />
);
