import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import Card from "./Card/Card";

const CardContainer = styled.div`
  display: flex;
  margin: 30px;
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    justify-content: space-around;
  }
  article {
    margin: 10px;
    flex: 0 calc(50% - 20px);
    box-sizing: border-box;
  }
`;

const BlogRoll = ({ data }) => {
  const posts = React.useMemo(() => {
    return data.allMarkdownRemark.edges;
  }, [data]);

  return (
    <CardContainer>
      {posts &&
        posts.map(({ node: post }, index) => (
          <article
            key={post.id}
            className={`blog-list-item tile is-child box notification ${
              post.frontmatter.featuredpost ? "is-featured" : ""
            }`}
          >
            {post.frontmatter.featuredimage ? (
              <div className="featured-thumbnail">
                <Card
                  backgroundImage={post.frontmatter.featuredimage}
                  linkContent={{
                    link: post.fields.slug,
                    button: "EXPLORE",
                  }}
                  heading={post.frontmatter.title}
                  subheading={post.excerpt}
                />
              </div>
            ) : null}
          </article>
        ))}
    </CardContainer>
  );
};

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 100)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
